import React from "react";
import './Header.css';

function Header(){
    return(
        <div className= "Header">
            <p>IRVANDEVS OFFICIAL SITE</p>
        </div>
    );
}

export default Header;